const FormManager = require('../../xd-form-manager');
const dynamicModuleRegistry = require('dynamic-module-registry');
const postRobot = require('post-robot');
const clientContext = dynamicModuleRegistry.get('client-context') || {};

const objectPath = require('object-path');
const topWindow = window.parent;
const heapTracking = require('../heap-tracking');
const target = dynamicModuleRegistry.get('widgetTargetURL');
const targetURL = (!target || !target.target) ? '' : target.target;
const usernameBasedLogin = dynamicModuleRegistry.get('usernameBasedLogin');

const NOT_ACTIVE_FACTOR_TEXT = 'You do not have any two-factor authentication devices. Please contact customer support at <a href="tel://888-596-3157">888-596-3157</a> for assistance';

function setUpLoginForm() {
  const pfPasswordValidation = this.$container.find('[name=pfPasswordValidation]').val();
  var options = {
    schemas: {
    },
    endPoint: {
      url: this.opts.loginSubmitUrl,
      dataType: 'json',
      method: 'POST',
      handlers: {
        success: this.loginSuccessHandler.bind(this),
        error: this.loginErrorHandler.bind(this)
      }
    },
    triggers: {
      validator: 'field'
    },
    errorDisplayStyle: 'field',
    spinner: true
  };
  if (pfPasswordValidation === 'true') {
    options.schemas.validator = require('../login-schema-pf');
  } else if (usernameBasedLogin) {
    options.schemas.validator = require('../login-schema-username');
  } else {
    options.schemas.validator = require('../login-schema');
  }
  if (clientContext.isVerifySession || clientContext.isDeferredSession) {
    const id = this.$container.find('[name=factorId]').val();
    const email = this.$container.find('[name=email]').val();
    const data = {factors: [{profile: {email}, id}]};
    this.showCodeSection(data);
  } else {
    this.loginFormManager = new FormManager(this.$container.find('[data-login-view]'), options);
    this.loginFormManager.on('success', this.loginSuccessHandler.bind(this));
    this.loginFormManager.on('validationSuccess', () => this.hideAllNotifications());
    this.loginFormManager.on('validationError', () => this.hideAllNotifications());
  }

  // enable button now that loginFormManager is ready
  this.$loginForm.find('[type=submit]').prop('disabled', false);
}

function filterData(data) {
  const filteredFactors = data.factors && data.factors.filter((factor) => objectPath.get(factor, 'profile.phone_number') !== '9999999999');
  return {...data, factors: filteredFactors};
}

function disableEmailFactor(data) {
  const patterns = clientContext.hideEmailPattern.split(' ');
  if (patterns.some((pattern) => new RegExp(pattern).test(window.parent.location.href))) {
    const filteredFactors = data.factors && data.factors.filter((factor) => factor.type !== 'OTP_EMAIL');
    return {...data, factors: filteredFactors};
  }
  return data;
}

function loginSuccessHandler(response) {
  let data = filterData(this.formatData(response));
  if (clientContext.hideEmailFactor) {
    data = disableEmailFactor(data);
  }

  const mfaStatus = 'PENDING_MFA';
  if (data && data.status === 'REDIRECT') {
    if (this.opts.isWidget) {
      postRobot.send(topWindow, 'loginSuccess', {data}).then(function(event) {
        return;
      });
    } else {
      document.location.href = data.name;
    }
    return;
  }
  if (data && data.status !== 200 && data.status !== mfaStatus) {
    this.loginErrorHandler(data);
    return;
  }

  if (data.status === mfaStatus) {
    const factors = data.factors;

    if (this.opts.isWidget) {
      postRobot.send(topWindow, 'userPendingMFA', { data }).then(function(event) {
        return;
      });
    }

    if (factors && factors.length === 0) {
      this.loginErrorHandler({ message: NOT_ACTIVE_FACTOR_TEXT });
    } else if (factors.length === 1) {
      const factor = factors[0];
      if (factor.type !== 'OTP_APP' || (factor.type === 'OTP_APP' && factor.profile.devices.length === 1)) {
        this.showCodeSection(data);
      } else {
        this.$factorsData = {...response, ...data};
        this.showChannelSection(factors);
      }
    } else {
      this.$factorsData = {...response, ...data};
      this.showChannelSection(factors);
    }
    return;
  }
  heapTracking.addSuccessLogin(targetURL);

  if (this.opts.isWidget) {
    postRobot.send(topWindow, 'loginSuccess', {data}).then(function(event) {
      return;
    });
  } else {
    // reload to trigger the redirect logic when logged in
    this.reloadPage();
  }
}

function loginErrorHandler(response) {
  const data = this.formatData(response);
  if (data?.status === 423 && data?.name === 'USER_ACCT_ALCHEMY_LOCKED') {
    heapTracking.addLockedAccount(data.message);
  }
  if (this.opts.isWidget) {
    this.loginFormManager.setFormState('error', data.message);
    postRobot.send(topWindow, 'loginError', {data}).then(function(event) {
      return;
    });
  } else {
    const messageData = {
      'title': 'Error Logging In',
      'message': data.message,
      'type': 'ERROR'
    };
    this.loginFormManager.setFormState('error');
    this.showErrorMessage(messageData, this.$loginForm);
  }
  this.loginFormManager.enableForm();
}

module.exports = {
  setUpLoginForm,
  loginSuccessHandler,
  loginErrorHandler,
  filterData
};

